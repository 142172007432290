import {
  Button,
  ImageWithMultipleVersions,
  Media,
  Product,
  TrackingAttributes,
  VideoMultipleVersions,
} from '@strapi-schema';
import type {
  TrackingAttributesNormalizedType,
  Media as ImageType,
  ProductDataType,
} from '@/redesign/api/strapi/types';
import {
  Media as BaseMedia,
  ResponsiveImageType,
} from '@wearemotivated/design-system/types';

export const getMediaAbsoluteUrl = (
  relativeUrl?: string,
  isVideo?: boolean,
) => {
  if (!relativeUrl) {
    return null;
  }
  // Remove everything before the first "/uploads/" in the URL
  if (relativeUrl.includes('/uploads/')) {
    relativeUrl = relativeUrl.replace(/.*\/uploads\//, '/uploads/');
  }

  if (relativeUrl.startsWith('http')) {
    return relativeUrl;
  }

  let prefix = isVideo
    ? process.env.NEXT_PUBLIC_CDN_VIDEO_PREFIX
    : process.env.NEXT_PUBLIC_CDN_IMAGE_PREFIX;

  if (relativeUrl.endsWith('.svg')) {
    prefix = '';
  } else if (process.env.MEDIA_URLS_REMOVE_FILENAME_EXTENSIONS == 'true') {
    relativeUrl = relativeUrl.replace(/\.[^/.]+$/, '');
  }

  return `${process.env.NEXT_PUBLIC_CDN_URL || process.env.NEXT_PUBLIC_STRAPI_API_URL}${prefix || ''}${relativeUrl}`;
};

export function isButtonType(ctaData: any): ctaData is Button {
  return (
    ctaData && typeof ctaData === 'object' && ctaData?.wizardRoute === undefined
  );
}

export const normalizeImageData = (
  imageData?: Media,
  isVideo?: boolean,
): ImageType => {
  if (!imageData) {
    return { url: '', alt: '' };
  }

  return {
    url: getMediaAbsoluteUrl(imageData?.attributes.url, isVideo) ?? '',
    alt: imageData?.attributes.alternativeText ?? '',
  };
};

export const normalizeVideoData = (videoData?: Media): ImageType => {
  if (!videoData) {
    return { url: '', alt: '' };
  }

  let url = getMediaAbsoluteUrl(videoData?.attributes.url, true) ?? '';
  url = url.endsWith(videoData.attributes.ext) ? url : `${url}`;

  return {
    url,
    alt: videoData?.attributes.alternativeText ?? '',
  };
};

export const normalizeMultipleImageData = (
  imageData: ImageWithMultipleVersions = {},
  isVideo?: boolean,
): ResponsiveImageType => {
  return {
    desktop: normalizeImageData(imageData?.desktop?.data, isVideo),
    mobile: normalizeImageData(imageData?.mobile?.data?.[0], isVideo),
  };
};

export const normalizeMultipleVideoData = (
  videoData: VideoMultipleVersions = {},
): ResponsiveImageType => {
  return {
    desktop: normalizeVideoData(videoData?.desktop?.data),
    mobile: normalizeVideoData(videoData?.mobile?.data?.[0]),
  };
};

export function normalizeTrackingAttributes(
  attrData: TrackingAttributes | TrackingAttributes[] | null | undefined,
) {
  let attr: any = attrData;
  if (typeof attr !== 'object' || !attr) {
    attr = {};
  }
  if (Array.isArray(attr)) {
    attr = attr[0] ?? {};
  }
  if (!attr.customAttributes) {
    attr.customAttributes = {};
  }
  attr.name = String(attr.name);
  attr.id = Number.isNaN(Number(attr.id)) ? 0 : Number(attr.id);

  return attr as TrackingAttributesNormalizedType;
}

export const normalizeProductData = (productData: Product): ProductDataType => {
  return {
    ...productData.attributes,
    id: productData.id,
    image: normalizeImageData(productData.attributes?.image?.data),
    productSlug: productData?.attributes?.productPage?.data?.attributes?.slug,
    callToAction: {
      text: productData.attributes.name || '',
      url: `/evaluation/${productData?.attributes.treatment?.data.attributes.defaultWizardRouteId}`,
      trackingAttributes: normalizeTrackingAttributes(
        productData?.attributes?.productPage?.data?.attributes
          ?.trackingAttributes,
      ),
    },
  };
};

// for local development - urls need to point to cdn
export const fixLocalUrl = (url: string, type = 'video') => {
  if (process.env.NEXT_PUBLIC_FLAG_FIX_LOCAL_MEDIA !== '1') {
    return url;
  }

  return url.replace(
    'http://127.0.0.1:1338',
    `https://scdn.motivated.com/${type}/upload/f_auto,q_auto/v1`,
  );
};

// for local development - urls need to point to cdn
export const fixLocalMedia = (media: BaseMedia) => {
  if (process.env.NEXT_PUBLIC_FLAG_FIX_LOCAL_MEDIA !== '1') {
    return media;
  }

  const newMedia = { ...media };
  newMedia.url = newMedia.url.replace(
    'http://127.0.0.1:1338',
    'https://scdn.motivated.com/image/upload/f_auto,q_auto/v1',
  );
  return newMedia;
};

// for local development - urls need to point to cdn
export const fixLocalImage = (image: ResponsiveImageType) => {
  if (process.env.NEXT_PUBLIC_FLAG_FIX_LOCAL_MEDIA !== '1') {
    return image;
  }

  const newImage: ResponsiveImageType = {
    desktop: { ...image.desktop },
    mobile: image.mobile ? { ...image.mobile } : undefined,
  };
  if (newImage.desktop?.url) {
    newImage.desktop.url = image.desktop.url.replace(
      'http://127.0.0.1:1338',
      'https://scdn.motivated.com/image/upload/f_auto,q_auto/v1',
    );
  }
  if (newImage.mobile?.url && image.mobile?.url) {
    newImage.mobile.url = image.mobile.url.replace(
      'http://127.0.0.1:1338',
      'https://scdn.motivated.com/image/upload/f_auto,q_auto/v1',
    );
  }

  return newImage;
};
